import MainLayout from "Component/Layout/MainLayout";
import DashboardCount from "Component/DashboardCount";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";

const Dashboard = () => {
  return (
    <MainLayout>
      <BodyHeaderLayout title={"Dashboard"} />

      <div className="flex flex-col md:flex-row gap-6 flex-wrap ">
        <DashboardCount
          title="Total Students"
          count={1009}
          bgColor="bg-[blue]"
          textColor="text-blue-500"
        />

        <DashboardCount
          title="Active Students"
          count={507}
          bgColor="bg-[green]"
          textColor="text-green-500"
        />

        <DashboardCount
          title="Pending Applicants"
          count={415}
          bgColor="bg-[orange]"
          textColor="text-orange-500"
        />

        <DashboardCount
          title="Rejected Applicants"
          count={25}
          bgColor="bg-[red]"
          textColor="text-red-500"
        />
      </div>
    </MainLayout>
  );
};

export default Dashboard;
