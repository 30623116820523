import { useDispatch } from "react-redux";
import { MdCancel } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";

import MobileNavItem from "./MobileNavItem";
import { setToggleMobileNav } from "../../../Store/AppSlice";
import { navigationRoutes } from "Utils/navigationRoutes";

const Mobile = () => {
  const dispatch = useDispatch();

  return (
    <div
      className="flex flex-col gap-5 overflow-y-auto h-full pb-3"
      style={{ scrollbarWidth: "thin" }}
    >
      <MdCancel
        onClick={() => dispatch(setToggleMobileNav(false))}
        size={30}
        className="self-end"
      />
      <div className="flex flex-col gap-3">
        <MobileNavItem
          Icon={RxDashboard}
          text={"Dashboard"}
          active={navigationRoutes.DASHBOARD}
        />
        <MobileNavItem
          Icon={FiUsers}
          text={"Applicants"}
          active={navigationRoutes.APPLICANTS}
        />

        <MobileNavItem
          Icon={HiOutlineClipboardDocumentCheck}
          text={"Applications"}
          active={navigationRoutes.APPLICATIONS}
        />
      </div>
    </div>
  );
};

export default Mobile;
