import { useNavigate } from "react-router-dom";

import MainLayout from "Component/Layout/MainLayout";
import CustomSearch from "Component/CustomSearch";
import trimText from "Utils/trimText";
import { navigationRoutes } from "Utils/navigationRoutes";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";

const Applications = () => {
  const navigate = useNavigate();
  return (
    <MainLayout>
      <BodyHeaderLayout title={"Open Applications"} />
      <div className="mt-3 flex flex-col gap-8">
        <CustomSearch
          className="w-full bg-white border-1"
          placeholderText="Search for a school"
        />
        <div className="grid grid-cols-3 sm:grid-cols-1 gap-3 sm:gap-5">
          {Array.from({ length: 5 }).map((_, index) => (
            <p
              key={index}
              onClick={() =>
                navigate(`${navigationRoutes.APPLICATIONS}/${index + 1}`)
              }
              className="text-[#152A75] underline text-sm cursor-pointer"
            >
              {trimText("Noble Intellect Academy, Yola", 30, 30)}
            </p>
          ))}
        </div>
      </div>
    </MainLayout>
  );
};

export default Applications;
