import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";

import PreLayout from "Component/Layout/PreLayout";
import { useRegisterMutation } from "Network/Auth/auth";
import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import { userRole } from "Utils/constants";
import { useNavigate } from "react-router-dom";
import { navigationRoutes } from "Utils/navigationRoutes";

const SignUpScreen = () => {
  const [createUser, { isLoading }] = useRegisterMutation();
  const navigate = useNavigate();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = (values, action) => {
    const data = {
      ...values,
      role: userRole.PARENT,
    };
    delete data.confirmPassword;

    createUser(data)
      .unwrap()
      .then((res) => {
        action.resetForm();
        navigate(navigationRoutes.LOGIN);
        toast.success("Account created successfully");
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };
  return (
    <div>
      <PreLayout title={"Registration"}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <div className="min-h-[30vh] w-full bg-white shadow-lg px-8 py-5 mt-3 flex flex-col gap-5">
              <Input
                withAsterik
                withTitle
                labelStyle="font-bold"
                title="First Name"
                name="firstName"
                placeholder="Enter First Name"
              />

              <Input
                withAsterik
                withTitle
                labelStyle="font-bold"
                title="Last Name"
                name="lastName"
                placeholder="Enter Last Name"
              />

              <Input
                withTitle
                labelStyle="font-bold"
                title="Email"
                placeholder="Enter Email"
                name="email"
              />

              <Input
                withTitle
                labelStyle="font-bold"
                title="Phone Number"
                placeholder="Enter Phone Number"
                name="phone"
              />

              <Input
                withAsterik
                withTitle
                title="Password"
                labelStyle="font-semibold"
                placeholder="Enter password"
                type="password"
                name="password"
              />

              <Input
                withAsterik
                withTitle
                title="Confirm Password"
                labelStyle="font-semibold"
                placeholder="Enter confrim password"
                type="password"
                name="confirmPassword"
              />

              <Button
                handleSubmit={props.handleSubmit}
                title="Register"
                className="w-full h-9"
                isLoading={isLoading}
              />

              <p className="text-center text-[14px] font-semibold">
                Already have an account?{" "}
                <span
                  className="text-primary cursor-pointer"
                  onClick={() => navigate(navigationRoutes.LOGIN)}
                >
                  Login
                </span>
              </p>
            </div>
          )}
        </Formik>
      </PreLayout>
    </div>
  );
};

export default SignUpScreen;
