import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

import {
  AccordionRoot,
  AccordionContent,
  AccordionTrigger,
  AccordionItem,
} from "Component/Accordion";
import DesktopContent from "./DesktopContent";
import MobileContent from "./MobileContent";
import ApplicationModal from "../ApplicationModal";
import Pagination from "Component/Pagination/Pagination";
import usePaginationAndSearch from "Utils/usePaginationAndSearch";
import { CURRENCY_SYMBOL } from "Utils/constants";

const Item = () => {
  const { page, setPage } = usePaginationAndSearch();
  const params = useParams();

  // const applicationsState = useGetApplicationsByShortNameQuery({
  //   page,
  //   shortName: params.shortName,
  // });

  // const applications = applicationsState?.data?.data;

  // if (applicationsState.isFetching)
  //   return (
  //     <div className="w-full flex justify-center">
  //       <CircularProgress />
  //     </div>
  //   );

  // if (applications.length === 0)
  //   return (
  //     <div className="w-full flex justify-center">
  //       <span>No Applications open yet.</span>
  //     </div>
  //   );

  return (
    <div>
      <AccordionRoot defaultValue={0}>
        {Array.from({ length: 7 }).map((_, index) => (
          <AccordionItem value={index.toString()}>
            <AccordionTrigger
              className="pt-3 sm:pt-1 sm:min-h-[70px]"
              accordionTriggerTitle={
                <p className="font-semibold sm:text-sm text-start">
                  Nursery Applications
                </p>
              }
            >
              <div className="flex justify-between w-full text-xs sm:text-[10px]">
                <p className="bg-[#fdd1ce] text-[#8b0e06] p-1  rounded-[2px]">
                  Closing Date: {dayjs(Date.now()).format("DD MMM YYYY")}
                </p>
                <p className="text-[#1e6f0a] bg-[#e4fcde] p-1 rounded-[2px]">
                  Application Fee: {CURRENCY_SYMBOL}
                  {Number("1000").toLocaleString()}
                </p>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <DesktopContent application={{}} />
              <MobileContent application={{}} />
            </AccordionContent>
          </AccordionItem>
        ))}
      </AccordionRoot>

      {/* {!applicationsState.isFetching &&
        applicationsState?.data?.data?.length !== 0 && (
          <div className="flex w-full justify-end mt-6">
            <Pagination
              onPageChange={setPage}
              pageTotal={applicationsState?.data?.totalPages}
              initialPage={applicationsState?.data?.currentPage}
            />
          </div>
        )} */}

      {/* <ApplicationModal /> */}
    </div>
  );
};

export default Item;
