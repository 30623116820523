import { IoMdArrowDropright } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import Logo from "Component/Logo";
import Avatar from "Component/Avatar";
import StatusIdentifier from "Component/StatusIdentifier";
import { statusConstant } from "Utils/constants";
import { navigationRoutes } from "Utils/navigationRoutes";

const ApplicantCard = () => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`${navigationRoutes.APPLICANTS}/1`)}
      className="min-h-[141px] w-full rounded-md text-xs bg-white flex flex-col relative cursor-pointer shadow-sm hover:shadow-md"
    >
      <div className="bg-[#D9D9D9] px-3 py-[2px] rounded-t-md flex gap-5 items-center">
        <Logo className="w-[57px] h-[27px]" />
        <p>Aiforce Comprehensive Secondary School, Yola</p>
      </div>

      <div className="flex gap-10 px-3 items-center h-full">
        <div className="flex flex-col gap-2">
          <Avatar className="h-[60px] w-[60px]" />
          <StatusIdentifier status={statusConstant.admitted} />
        </div>

        <div className="flex flex-col gap-3">
          <p className="font-bold">Nibras Abubakar Mohammad</p>

          <p className="font-bold">APN0012</p>

          <p>Nursery | Nursery 1</p>
        </div>
      </div>

      <IoMdArrowDropright className="absolute bottom-3 right-3 text-xl" />
    </div>
  );
};

export default ApplicantCard;
