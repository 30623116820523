export const navigationRoutes = {
  DASHBOARD: "/dashboard",
  APPLICANTS: "/applicants",
  APPLICATIONS: "/applications",
  VIEW_APPLICATION: "/applications/view",
  VIEW_APPLICANT: "/applicants/view",

  SIGNUP: "/signup",
  LOGIN: "/",

  FORGET_PASSWORD: "/forget-password",
  RESET_PASSWORD: "/reset-password",
  VERIFY_EMAIL: "/verifying",
  PROFILE: "/profile",
  RESEND_VERIFY_EMAIL: "/resend-verify-email",
};
