import MainLayout from "Component/Layout/MainLayout";
import ApplicantCard from "./component/ApplicantCard";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";

const Applicants = () => {
  return (
    <MainLayout>
      <BodyHeaderLayout title={"Applicants"} />
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-5 mt-3">
        <ApplicantCard />
        <ApplicantCard />
        <ApplicantCard />
        <ApplicantCard />
      </div>
    </MainLayout>
  );
};

export default Applicants;
