import { useEffect } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";

import Nav from "../Nav/Nav";
import Header from "../Header/Header";
import Footer from "../Footer";
import PropTypes from "prop-types";
import { LoadingPage } from "./PreLayout";
import { navigationRoutes } from "Utils/navigationRoutes";
import { useGetMyProfileQuery } from "Network/Auth/profile";

const MainLayout = ({ children, isLoading, title }) => {
  const toggleNav = useSelector((state) => state.appSlice)?.toggleNav;
  const navigate = useNavigate();

  const token = localStorage.getItem("parent_token");
  const myProfileState = useGetMyProfileQuery();

  useEffect(() => {
    if (!token) {
      navigate(navigationRoutes.LOGIN, { replace: true });
    }
  }, [token]);

  if (myProfileState.isLoading) {
    return <LoadingPage />;
  }

  if (myProfileState.error) {
    navigate(navigationRoutes.LOGIN, { replace: true });
  }

  return (
    <div className="flex flex-col h-[100vh] bg-main-bg sm:bg-[#F5F5F5]">
      <Header userInfo={myProfileState?.data} />

      <div className="flex flex-grow">
        <Nav />

        <div
          className={`sm:bg-[#F5F5F5] pr-[20px] flex flex-col gap-1 ${
            toggleNav ? "pl-[265px]" : "pl-[80px]"
          }  sm:px-3 sm:py-1 pb-5 pt-[80px] sm:pt-[60px] bg-main-bg w-full`}
        >
          {!isLoading ? (
            <>
              {title.length !== 0 && (
                <div className="mt-[20px] bg-white shadow-sm font-medium px-4 py-3">
                  <h2>{title}</h2>
                </div>
              )}
              {children}
            </>
          ) : (
            <div className="flex justify-center mt-3">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

MainLayout.prototype = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
};

MainLayout.defaultProps = {
  isLoading: false,
  title: "",
};

export default MainLayout;
