import Logo from "../Logo";

const PreLayout = ({ title, children, className }) => {
  return (
    <div className=" min-h-[100vh] bg-main-bg sm:bg-white pt-16 sm:pt-10">
      <div
        className={`w-[32vw] sm:w-[100vw] m-auto flex flex-col items-center ${className}`}
      >
        <div className="flex flex-col items-center gap-2">
          <Logo />
          <p className="text-[14px] font-bold text-primary text-center">
            {title}
          </p>
        </div>

        {children}
      </div>
    </div>
  );
};

export default PreLayout;

export const LoadingPage = () => {
  return (
    <div className="h-[100vh] w-full flex items-center justify-center">
      <img
        className="animate-fade-in-out inset-0 object-cover"
        src={"/images/logo-loader.png"}
        alt="soft school"
      />
    </div>
  );
};
