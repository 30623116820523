import MainLayout from "Component/Layout/MainLayout";
import Logo from "Component/Logo";
import List from "./List";
import BackButton from "Component/BackButton";
import { navigationRoutes } from "Utils/navigationRoutes";

const ViewApplication = () => {
  return (
    <MainLayout>
      <div className="mt-3">
        <BackButton navigateTo={navigationRoutes.APPLICATIONS} />
        <div className="flex gap-20 sm:flex-col sm:gap-5 items-center ">
          <Logo />
          <div className="text-xs flex flex-col sm:text-center items-center gap-1">
            <p className="text-sm sm:text-center font-bold uppercase">
              AIRFORCE COMPREHENSIVE SECONDARY SCHOOL YOLA, ADMISSION FOR
              2024/205 ACADEMIC SESSION
            </p>
            <p>Address: Numan Road, OPP FGGC Yola, Yola North Adamawa State</p>
            <p>+2348140409672, +2348130692747, +23481010118601</p>
          </div>
        </div>

        <List />
      </div>
    </MainLayout>
  );
};

export default ViewApplication;
