import { TableWrapper, THead } from "Component/Table";
import Button from "Component/Forms/Button";
import useApplicationStore from "../../hooks/useApplicationStore";

const DesktopContent = ({ application }) => {
  const { setOpen, setSelectedApplication, setSelectedClass } =
    useApplicationStore();

  return (
    <TableWrapper className="w-full flex justify-center">
      <table className="w-[90%] ">
        <THead className="w-full border-b-1 text-[14px]">
          <td className="w-[48%]">Classes</td>
          <td className="w-[47%]">Category</td>
          <td />
        </THead>

        <tbody>
          {Array.from({ length: 2 })?.map((_, ind) => (
            <tr className="border-b-1" key={ind}>
              <td className="py-3">Nursery 1</td>
              <td>{"General"}</td>
              <td>
                <Button
                  title="Apply"
                  className="w-full"
                  handleSubmit={() => {
                    setOpen(true);
                    setSelectedApplication({});
                    setSelectedClass({});
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default DesktopContent;
