import { useEffect } from "react";
import { Formik } from "formik";
import PreLayout from "Component/Layout/PreLayout";
import Button from "Component/Forms/Button";
import Input from "Component/Forms/Input";
import * as Yup from "yup";
import { useResendVerifyEmailMutation } from "Network/Auth/auth";
import { toast } from "react-toastify";
import { roles } from "Utils/constants";

const ResendVerifyEmail = () => {
  const [resendVerifyEmail, state] = useResendVerifyEmailMutation();

  useEffect(() => {
    if (state.isSuccess) toast.success("Success");
  }, [state.isSuccess]);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleSubmit = (values) => {
    resendVerifyEmail({
      ...values,
      role: roles.PARENT,
    });
  };

  return (
    <PreLayout
      title={"Resend Email Verification"}
      className="max-w-[30vw] sm:max-w-full"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="min-h-[30vh] w-full bg-white px-8 py-5 flex flex-col gap-5 mt-3">
            {state.isUninitialized || !state.isSuccess ? (
              <div className="flex flex-col gap-4 w-full">
                <p className="text-center text-[14px] text-primary font-semibold cursor-pointer">
                  Check your email or resend another link
                </p>

                <Input
                  withTitle
                  title="Email"
                  placeholder="Enter email"
                  name="email"
                />

                <Button
                  isLoading={state.isLoading}
                  handleSubmit={props.handleSubmit}
                  title="Resend link"
                />
              </div>
            ) : (
              <div className="flex flex-col items-center gap-6">
                <p className="text-center text-[14px]">
                  If account exist with this email, a verification link has been
                  send to it.
                </p>

                {!state.isLoading ? (
                  <p
                    onClick={props.handleSubmit}
                    className="cursor-pointer text-center text-[14px] text-primary font-semibold"
                  >
                    Resend Link?
                  </p>
                ) : (
                  <p className="text-center text-[14px] text-primary font-semibold">
                    ...
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </Formik>
    </PreLayout>
  );
};

export default ResendVerifyEmail;
