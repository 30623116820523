import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import PreLayout from "Component/Layout/PreLayout";
import Button from "Component/Forms/Button";
import Input from "Component/Forms/Input";
import { navigationRoutes } from "Utils/navigationRoutes";
import { userRole } from "Utils/constants";
import { useLoginMutation } from "Network/Auth/auth";

const Login = () => {
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const [loginUser, { isLoading }] = useLoginMutation();

  const handleSubmit = (values) => {
    const data = {
      ...values,
      role: userRole.PARENT,
    };

    loginUser(data)
      .unwrap()
      .then((res) => {
        if (!res?.result?.user?.emailVerified) {
          toast.error("Your account is not verified");
          navigate(navigationRoutes.RESEND_VERIFY_EMAIL);
        } else {
          navigate(navigationRoutes.DASHBOARD);
          localStorage.setItem("parent_token", res.token);
          toast.success("Login successfully");
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };

  return (
    <PreLayout title="School Login" className="max-w-[30vw] sm:max-w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="min-h-[30vh] bg-white px-8 py-5 mt-3 flex flex-col gap-4 w-full">
            <Input
              withTitle
              title="Email"
              placeholder="Enter email"
              name="email"
            />

            <Input
              withTitle
              title="Password"
              placeholder="Enter password"
              name="password"
              type="password"
            />

            <Button
              isLoading={isLoading}
              handleSubmit={props.handleSubmit}
              title="Login"
              className=""
            />

            <p
              onClick={() =>
                !isLoading && navigate(navigationRoutes.FORGET_PASSWORD)
              }
              className="text-center text-[14px] text-primary font-semibold cursor-pointer"
            >
              forgot password?
            </p>

            <p
              className="text-center text-[14px] font-semibold"
              onClick={() => !isLoading && navigate(navigationRoutes.SIGNUP)}
            >
              Dont have an account yet?{" "}
              <span className="text-primary cursor-pointer">
                Create account
              </span>
            </p>
          </div>
        )}
      </Formik>
    </PreLayout>
  );
};

export default Login;
