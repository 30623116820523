import { Link, useLocation, useParams } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { setToggleNav } from "../../../Store/AppSlice";

const NavItem = ({ Icon, text, active, toggle }) => {
  const { pathname } = useLocation();
  const path = `/${pathname.split("/")[1]}`;

  return (
    <Link to={active}>
      <div
        className={`flex gap-5 px-4 py-2 ${path === active && "font-bold text-primary"}  hover:text-primary cursor-pointer font-lighter text-[14px] items-center`}
      >
        <Icon size={20} />
        {toggle && <p>{text}</p>}
      </div>
    </Link>
  );
};

export default NavItem;

/**
 * Generate a drop-down navigation item with icon, text, and optional items.
 *
 * @param {Object} Icon - The icon component for the navigation item
 * @param {string} text - The text to display for the navigation item
 * @param {string} active - The active path for the navigation item
 * @param {boolean} toggle - A boolean to toggle the drop-down
 * @param {Array} items - An array of optional items for the drop-down
 * @return {JSX.Element} The drop-down navigation item component
 */
export const DropDownNavItem = ({ Icon, text, active, toggle, items = [] }) => {
  const { pathname } = useLocation();
  const path = `/${pathname.split("/")[1]}`;
  const dispatch = useDispatch();

  const [showDropDown, setShowDropDown] = useState(path === active && toggle);

  return (
    <div>
      <div
        onClick={() => {
          setShowDropDown((prev) => !prev);
          dispatch(setToggleNav(true));
        }}
        className={`flex justify-between px-4 py-2 ${path === active && "bg-primary text-white"} hover:bg-primary hover:text-white cursor-pointer items-center`}
      >
        <div className="flex gap-5 items-center">
          <Icon size={23} />
          {toggle && <p className="text-[14px]">{text}</p>}
        </div>
        {toggle && (
          <div>{!showDropDown ? <IoIosArrowDown /> : <IoIosArrowUp />}</div>
        )}
      </div>

      <AnimatePresence>
        {showDropDown && toggle && (
          <motion.div
            className={`pl-12 py-4 bg-white`}
            initial={{
              y: path === active ? 0 : "-30%",
              opacity: path === active ? 1 : 0,
            }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "-30%", opacity: 0 }}
            transition={{ ease: "easeInOut" }}
          >
            {items.map((val, index) => (
              <Link to={val?.link}>
                <div
                  key={index}
                  className={`flex gap-3 items-center cursor-pointer p-2 hover:opacity-70`}
                >
                  <p
                    className={`w-3 h-3 rounded-full border-1 border-main ${pathname === val?.link && "bg-primary"}`}
                  />
                  <p
                    className={`text-[12px] text-primary font-semibold ${pathname !== val?.link && "opacity-70"}`}
                  >
                    {val?.name}
                  </p>
                </div>
              </Link>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
