export const tags = {
  SCHOOL_APPLICANTS: "schoolapplicants",
  MY_PROFILE_CONTROL_TAG: "myprofilecontroltag",
};

export const IMAGE_SIZE = 300 * 1024;

export const roles = {
  SUPER_ADMIN: "super_admin",
  PARENT: "parent",
};

export const genderList = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

export const CURRENCY_SYMBOL = "₦";

export const statusConstant = {
  pending: "pending",
  admitted: "admitted",
  successful: "successful",
  declined: "declined",
  failed: "failed",
  published: "published",
  notPublish: "notPublish",
};

export const filesAllowed = ["jpeg", "png", "jpg"];

export const userRole = {
  ADMIN: "super_admin",
  PARENT: "parent",
};
