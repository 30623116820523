import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";

import Mobile from "./Mobile";
import Desktop from "./Desktop";
import { setToggleNav } from "../../Store/AppSlice";

const Nav = () => {
  const dispatch = useDispatch();

  const mobileToggle = useSelector((state) => state.appSlice)?.toggleMobileNav;
  const toggleNav = useSelector((state) => state.appSlice)?.toggleNav;

  return (
    <div>
      <div className="fixed h-[100%] z-20 mt-[63px]">
        <motion.div
          className={`bg-white sm:hidden relative h-full border-t-1 py-12 border-primary`}
          initial={{ width: toggleNav ? "245px" : "60px" }}
          animate={{ width: toggleNav ? "245px" : "60px" }}
        >
          <div
            onClick={() => dispatch(setToggleNav(!toggleNav))}
            className="absolute top-3 right-[-7px] text-primary cursor-pointer"
          >
            {toggleNav ? (
              <IoIosArrowDropleft size={20} />
            ) : (
              <IoIosArrowDropright size={20} />
            )}
          </div>
          <Desktop toggle={toggleNav} />
        </motion.div>
      </div>

      <AnimatePresence>
        {mobileToggle && (
          <motion.div
            className="lg:hidden w-full h-full z-[100] mt-[48px] bg-white fixed border-t-1 px-4 py-2 border-primary"
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ ease: "easeInOut" }}
          >
            <Mobile />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Nav;
