import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { tags } from "../Utils/constants";

const api = createApi({
  refetchOnReconnect: true,
  refetchOnFocus: true,
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("parent_token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [tags.SCHOOL_APPLICANTS, tags.MY_PROFILE_CONTROL_TAG],
});

export default api;
