import dayjs from "dayjs";

import MainLayout from "Component/Layout/MainLayout";
import Logo from "Component/Logo";
import Avatar from "Component/Avatar";
import cn from "Utils/cn";
import Button from "Component/Forms/Button";
import { statusConstant } from "Utils/constants";
import StatusIdentifier from "Component/StatusIdentifier";
import BackButton from "Component/BackButton";
import { navigationRoutes } from "Utils/navigationRoutes";

const ViewApplicant = () => {
  return (
    <MainLayout>
      <BackButton navigateTo={navigationRoutes.APPLICANTS} />
      <div className="bg-white p-3 flex flex-col gap-4">
        <div className="flex sm:flex-col justify-between sm:gap-2 items-center">
          <div className="flex sm:flex-col gap-10 sm:gap-2 items-center">
            <Logo />
            <p className="text-xs font-bold sm:font-medium sm:text-center">
              AIRFORCE COMPREHENSIVE SECONDARY SCHOOL 2024/2025 ACADEMIC
              SESSIONS ADMISSIONS
            </p>
          </div>

          <StatusIdentifier status={statusConstant.admitted} />
        </div>

        <div className="flex sm:flex-col sm:items-center gap-3 border-1 sm:border-none">
          <Avatar className="w-[210px] sm:w-[100px] h-[210px] sm:h-[100px] rounded-none" />

          <div className="flex flex-col w-full border-l-1 sm:border-1">
            <Row isHeader title={"Application ID"} text={"1228474774"} />
            <Row
              capitaliseTitle
              background
              title={"Last Name"}
              text={"Yuauf"}
            />
            <Row capitaliseTitle title={"First Name"} text={"Mustapha"} />
            <Row
              capitaliseTitle
              background
              title={"Other Name"}
              text={"Mustapha"}
            />
            <Row
              capitaliseTitle
              title={"D.o.b"}
              text={dayjs().format("DD/MM/YYYY")}
            />
            <Row capitaliseTitle background title={"Gender"} text={"Male"} />

            <Row
              capitaliseTitle
              title={"Section Applied for"}
              text={"Primary"}
            />
            <Row
              capitaliseTitle
              background
              title={"Class"}
              text={"SSS 1 Science"}
            />
          </div>
        </div>

        <div className="flex flex-col w-full border-1">
          <Row background isHeader title={"Guardian Information"} />
          <Row title={"Full Name"} text={"Mustapha Yuauf"} />
          <Row background title={"Email"} text={"onemusty.z@gmail.com"} />
          <Row title={"Relationship"} text={"father"} />
        </div>

        <div className="flex flex-col w-full border-1">
          <Row background isHeader title={"Document's Uploaded"} />
          <Row title={"NIN"} link={"https://www.google.com/"} />
          <Row
            background
            title={"Birth Certificate"}
            link={"https://www.google.com/"}
          />
        </div>

        <Button
          title="Download Admission Letter"
          className="w-[250px] sm:w-full sm:text-xs bg-[#23810C] py-2"
        />
      </div>
    </MainLayout>
  );
};

export default ViewApplicant;

const Row = ({ title, text, link, isHeader, capitaliseTitle, background }) => {
  return (
    <div
      className={cn(
        "flex gap-3 sm:justify-between h-[26px] text-[12px] sm:text-[10px] items-center w-full",
        isHeader && "font-bold",
        background && "bg-slate-100",
      )}
    >
      <p
        className={cn(
          "px-3 w-[30%] sm:w-full",
          (isHeader || capitaliseTitle) && "uppercase",
        )}
      >
        {title}
      </p>
      {text && <p className="border-1 h-full sm:hidden" />}
      {text && <p className="px-3 w-[69%] sm:w-full">{text}</p>}
      {link && (
        <a
          className="px-3 w-[69%] sm:w-full text-blue-600"
          target="_blank"
          rel="noreferrer"
          href={link}
        >
          View
        </a>
      )}
    </div>
  );
};
