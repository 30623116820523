import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppToast from "./Component/Toaster/AppToast";
import { navigationRoutes } from "Utils/navigationRoutes";

import SignUpScreen from "Screens/SignUpScreen";
import Login from "Screens/Login";
import ForgetPassword from "Screens/ForgetPassword/ForgetPassword";
import ResetPassword from "Screens/ForgetPassword/ResetPassword";
import VerifyEmail from "Screens/VerifyEmail/VerifyEmail";
import ResendVerifyEmail from "Screens/VerifyEmail/ResendVerifyEmail";

import Dashboard from "Screens/Main/Dashboard";
import Applicants from "Screens/Main/Applicants";
import Applications from "Screens/Main/Applications";
import ViewApplication from "Screens/Main/Applications/View/ViewApplication";
import ViewApplicant from "Screens/Main/Applicants/View/ViewApplicant";

function App() {
  return (
    <div>
      <AppToast />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path={navigationRoutes.SIGNUP} element={<SignUpScreen />} />

          <Route
            path={navigationRoutes.FORGET_PASSWORD}
            element={<ForgetPassword />}
          />
          <Route
            path={navigationRoutes.RESET_PASSWORD}
            element={<ResetPassword />}
          />
          <Route
            path={navigationRoutes.VERIFY_EMAIL}
            element={<VerifyEmail />}
          />
          <Route
            path={navigationRoutes.RESEND_VERIFY_EMAIL}
            element={<ResendVerifyEmail />}
          />

          <Route path={navigationRoutes.DASHBOARD} element={<Dashboard />} />
          <Route path={navigationRoutes.APPLICANTS} element={<Applicants />} />
          <Route
            path={navigationRoutes.APPLICATIONS}
            element={<Applications />}
          />
          <Route
            path={`${navigationRoutes.APPLICATIONS}/:id`}
            element={<ViewApplication />}
          />
          <Route
            path={`${navigationRoutes.APPLICANTS}/:id`}
            element={<ViewApplicant />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
