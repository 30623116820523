import { RxDashboard } from "react-icons/rx";
import NavItem from "./NavItem";
import { FiUsers } from "react-icons/fi";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";

import { navigationRoutes } from "Utils/navigationRoutes";

const Desktop = ({ toggle }) => {
  return (
    <div
      className="flex flex-col gap-5 overflow-y-auto h-full pb-3"
      style={{ scrollbarWidth: "thin" }}
    >
      <NavItem
        Icon={RxDashboard}
        text={"Dashboard"}
        active={navigationRoutes.DASHBOARD}
        toggle={toggle}
      />
      {process.env.REACT_APP_ENV === "development" && (
        <NavItem
          Icon={FiUsers}
          text={"Applicants"}
          active={navigationRoutes.APPLICANTS}
          toggle={toggle}
        />
      )}

      {process.env.REACT_APP_ENV === "development" && (
        <NavItem
          Icon={HiOutlineClipboardDocumentCheck}
          text={"Applications"}
          active={navigationRoutes.APPLICATIONS}
          toggle={toggle}
        />
      )}
    </div>
  );
};

export default Desktop;
